import React, { useState } from 'react';
import { Box, Tabs as MuiTabs, Tab, Button, Typography, Card, CardContent, CardActions, CircularProgress } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';

const TabsComponent = ({
  tabValue,
  setTabValue,
  saveData,
  fetchOnlineRecords,
  onlineRecords,
  handleLoadOnline,
  handleRemoveOnline,
  handleSaveOnline,
  jsonInput,
  setJsonInput,
  handleLoad,
  resetPoints,
  haversineDistance,
  currentLocation, // Get current user location from the prop
  
  }) => {
  const [loadingRefresh, setLoadingRefresh] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleRefreshRecords = async () => {
    setLoadingRefresh(true);
    await fetchOnlineRecords();
    setLoadingRefresh(false);
  };

  const handleSaveData = async () => {
    setLoadingSave(true);
    await handleSaveOnline();
    setLoadingSave(false);
  };
  
  
const sortByDistanceToUser = (records, userLocation) => {
  if (!userLocation || typeof userLocation.lng !== 'number' || typeof userLocation.lat !== 'number') {
    console.error("Invalid user location for distance calculation.");
    return records; // Return unsorted records if user location is invalid
  }

  // Convert userLocation to [longitude, latitude] array
  const userLocationArray = [userLocation.lng, userLocation.lat];

  return records
    .map((record) => {
      const startingPoint = record.points?.[0]; // Ensure points array exists and has a first point
      if (!startingPoint || startingPoint.length !== 2) {
        console.error("Invalid starting point in record:", record);
        return record; // Skip distance calculation if starting point is invalid
      }

      const distanceToUser = haversineDistance(userLocationArray, startingPoint);
      return { ...record, distanceToUser };
    })
    .filter((record) => record.distanceToUser !== undefined) // Only keep records with valid distances
    .sort((a, b) => a.distanceToUser - b.distanceToUser); // Sort by nearest distance
};



  
  const [selectedFilters, setSelectedFilters] = useState({
	  '10km': false,
	  '21km': false,
	  'other': false,
	});

	const applyFilters = (records) => {
	  const { '10km': filter10km, '21km': filter21km, 'other': filterOther } = selectedFilters;

	  if (!filter10km && !filter21km && !filterOther) return records; // No filters applied

	  return records.filter((record) => {
		const distance = record.distance;

		// Check if the record falls into any of the selected filters
		if (filter10km && distance >= 10 && distance < 11) return true;
		if (filter21km && distance >= 21) return true;
		if (filterOther && (distance < 10 || (distance >= 11 && distance < 21))) return true;

		return false;
	  });
	};

  return (
    <>
      <MuiTabs
        value={tabValue}
        onChange={handleTabChange}
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
      >
        <Tab label="Export" />
        <Tab label="Saved Records" />
        <Tab label="Import" />
        <Tab label="Reset" />
      </MuiTabs>

      {/* Export Tab */}
      <Box role="tabpanel" hidden={tabValue !== 0} p={{ xs: 1, sm: 3 }}>
        {tabValue === 0 && (
          <Box>
            <Box
              component="pre"
              sx={{
                backgroundColor: '#f5f5f5',
                padding: '10px',
                borderRadius: '5px',
                overflow: 'auto',
                fontSize: '14px',
                maxHeight: '300px',
                whiteSpace: 'pre-wrap',
              }}
            >
              <code>{saveData}</code>
            </Box>

            <Button
              variant="outlined"
              startIcon={<ContentCopyIcon />}
              onClick={() => navigator.clipboard.writeText(saveData)}
              sx={{ marginTop: '10px', padding: '10px 20px' }}
            >
              Copy to Clipboard
            </Button>
            <LoadingButton
              variant="contained"
              color="primary"
              onClick={handleSaveData}
              loading={loadingSave}
              loadingPosition="start"
              startIcon={<SaveIcon />}
              sx={{ marginLeft: '10px', marginTop: '10px', padding: '10px 20px' }}
            >
              Save to Online
            </LoadingButton>
          </Box>
        )}
      </Box>




{/* Saved Records Tab */}
<Box role="tabpanel" hidden={tabValue !== 1} p={{ xs: 1, sm: 3 }}>
  {tabValue === 1 && (
    <Box>
      {/* Filter Buttons */}
		<Box sx={{ marginBottom: '10px', display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
		  <Button
			variant={selectedFilters['10km'] ? 'contained' : 'outlined'}
			color="primary"
			onClick={() => setSelectedFilters((prev) => ({ ...prev, '10km': !prev['10km'] }))}
		  >
			10km
		  </Button>
		  <Button
			variant={selectedFilters['21km'] ? 'contained' : 'outlined'}
			color="primary"
			onClick={() => setSelectedFilters((prev) => ({ ...prev, '21km': !prev['21km'] }))}
		  >
			21km
		  </Button>
		</Box>

      <LoadingButton
        onClick={handleRefreshRecords}
        variant="contained"
        color="primary"
        sx={{ marginBottom: '10px' }}
        loading={loadingRefresh}
        loadingIndicator="Refreshing..."
      >
        Refresh Records
      </LoadingButton>

      {onlineRecords.length > 0 ? (
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: {
              xs: '1fr', // Single column for extra small screens
              sm: 'repeat(auto-fill, minmax(300px, 1fr))', // Responsive layout for larger screens
            },
            gap: '20px',
          }}
        >
          {sortByDistanceToUser(applyFilters(onlineRecords), currentLocation).map((record) => (
            <Card key={record.id} sx={{ maxWidth: { xs: '100%', sm: 345 }, width: '100%' }}>
              <CardContent>
                {/* Distance as title */}
                <Typography variant="h5" component="div">
                  {record.distance} km
                </Typography>

                {/* Name in bold text */}
                <Typography variant="body1" fontWeight="bold">
                  {record.name}
                </Typography>
				
				{/* Conditionally render distance to user's location */}
				{record.distanceToUser !== undefined && (
				  <Typography variant="body2" color="text.secondary">
					{`${record.distanceToUser.toFixed(2)} km from your location`}
				  </Typography>
				)}
				
                {/* Places with arrow symbols */}
                <Typography variant="body2" color="text.secondary">
                  {record.places.join(' ➜ ')}
                </Typography>

                {/* Date of save */}
                <Typography
                  variant="caption"
                  color="text.secondary"
                  sx={{ display: 'block', marginTop: '10px' }}
                >
                  {new Date(record.timestamp).toLocaleDateString()}
                </Typography>

              </CardContent>

              {/* Action buttons */}
              <CardActions sx={{ justifyContent: 'flex-end' }}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => handleLoadOnline(record.id)}
                  sx={{ padding: '10px 20px' }}
                >
                  Load
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    if (window.confirm('Are you sure you want to remove this record?')) {
                      handleRemoveOnline(record.id);
                    }
                  }}
                  sx={{ padding: '10px 20px' }}
                >
                  Remove
                </Button>
              </CardActions>
            </Card>
          ))}
        </Box>
      ) : (
        <Typography>No valid records found</Typography>
      )}
    </Box>
  )}
</Box>


      {/* Import Tab */}
      <Box role="tabpanel" hidden={tabValue !== 2} p={{ xs: 1, sm: 3 }}>
        {tabValue === 2 && (
          <Box>
            <Typography variant="body2" gutterBottom>
              Paste the JSON string of points here:
            </Typography>
            <textarea
              value={jsonInput}
              onChange={(e) => setJsonInput(e.target.value)}
              rows={10}
              style={{ width: '100%', padding: '10px', fontSize: '16px' }}
              placeholder="Paste your JSON here..."
            />
            <Button
              variant="contained"
              color="secondary"
              onClick={handleLoad}
              style={{ marginTop: '10px' }}
            >
              Load Points
            </Button>
          </Box>
        )}
      </Box>

      {/* Reset Tab */}
      <Box role="tabpanel" hidden={tabValue !== 3} p={{ xs: 1, sm: 3 }}>
        {tabValue === 3 && (
          <Box>
            <Typography variant="body2" gutterBottom>
              Are you sure you want to reset all points?
            </Typography>
            <Button
              variant="contained"
              color="error"
              onClick={resetPoints}
            >
              Reset Points
            </Button>
          </Box>
        )}
      </Box>
    </>
  );
};

export default TabsComponent;
